import { useHead, useNuxtApp } from '#imports'
import { type MaybeRefOrGetter } from 'vue'

import { useAddCanonicalLink } from '~/scopes/seo/composables/useAddCanonicalLink'

export function useHubHead() {
  const nuxtApp = useNuxtApp()

  function setHead({
    title,
    description,
  }: {
    title: string | undefined
    description: MaybeRefOrGetter<string | undefined>
  }) {
    // This allows us to call useHead in a context where we don't have access to the Nuxt App
    // for example, after awaiting a function
    void nuxtApp.runWithContext(() => {
      useAddCanonicalLink()
      useHead({
        title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
          {
            content: title,
            property: 'og:title',
            hid: 'og:title',
          },
          {
            content: description,
            property: 'og:description',
            hid: 'og:description',
          },
          {
            content: title,
            property: 'twitter:title',
            hid: 'twitter:title',
          },
          {
            content: description,
            property: 'twitter:description',
            hid: 'twitter:description',
          },
        ],
      })
    })
  }

  return { setHead }
}
