<template>
  <div
    v-if="contentHubData"
    class="mx-auto px-24 pb-56 lg:max-w-[1120px] lg:px-0"
  >
    <RevBreadcrumb
      v-if="breadcrumbs"
      ariaLabel="breadcrumb"
      :breads="breadcrumbs"
      class="pb-24 md:pb-32"
      data-test="content-hub-breadcrumb"
      @click-breadcrumb="trackBreadcrumbClick"
    />

    <h1 class="heading-1 mb-8">
      {{ contentHubData.title }}
    </h1>

    <div class="flex flex-col">
      <HubIntro :rich-text-intro="contentHubData.introductionText" />

      <ArticleCardsCollection
        v-if="contentHubData.featured"
        :articles="
          mapArticleCardsListToArticleCards(
            currentUrl,
            contentHubData.featured.props,
          )
        "
        class="mt-56"
        layout="carousel"
        :title="i18n(hubTranslations.featuredArticlesTitle)"
      />

      <div
        v-for="{ props, id } of contentHubData.categories"
        :key="id"
        class="mt-56"
      >
        <ArticleCardsCollection
          v-if="mapToArticles(props).length"
          :articles="mapArticleCardsListToArticleCards(currentUrl, props)"
          :cta="generateCTALink(props.ctaLink, props.title)"
          layout="carousel"
          :subtitle="props.subtitle"
          :title="props.title"
        />
      </div>

      <ArticleCardsCollection
        v-if="contentHubData.articles"
        :articles="
          mapArticlesToArticleCards(currentUrl, contentHubData.articles)
        "
        layout="grid"
      />

      <CategoryTree
        v-if="contentHubData.categoryTree.props.categoryTree?.length"
        :categoryTree="contentHubData.categoryTree.props.categoryTree"
        :title="contentHubData.categoryTree.props.title"
        :tracking="trackingParams"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  useRequestURL,
  useRouteLocationWithLocale,
  useRouteParams,
} from '#imports'
import { computed } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import ArticleCardsCollection from '@backmarket/nuxt-layer-cms/ArticleCardsCollection.vue'
import CategoryTree from '@backmarket/nuxt-layer-cms/CategoryTree.vue'
import {
  mapArticleCardsListToArticleCards,
  mapArticlesToArticleCards,
  mapToArticles,
} from '@backmarket/nuxt-layer-cms/utils/articleMapper'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import { transformHref } from '../../../cms/helpers/link/transformHref'
import { CMS } from '../../../cms/routes-names'
import breadcrumbTranslations from '../../../cms/shared-components/Breadcrumb/Breadcrumb.translations'
import { HOME } from '../../../home/route-names'
import HubIntro from '../components/HubIntro/HubIntro.vue'
import { useFetchContentHub } from '../composables/useFetchContentHub'

import hubTranslations from './ArticlesHub.translations'

const currentUrl = useRequestURL()
const i18n = useI18n()
const { trackClick } = useTracking()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const routeParams = useRouteParams()

const contentHubData = await useFetchContentHub()

const trackingParams = { name: 'discover_our_articles', zone: CMS.ARTICLE_HUB }

const breadcrumbs = computed(() => {
  if (!contentHubData.value?.breadcrumb) return []

  const hasBreadcrumbs = contentHubData.value.breadcrumb.length > 0
  const remainingBreadcrumbs = contentHubData.value.breadcrumb.slice(1)

  return [
    {
      link: resolveLocalizedRoute({ name: HOME }),
      title: i18n(breadcrumbTranslations.home),
    },
    {
      link: resolveLocalizedRoute({ name: CMS.ARTICLE_ROOT_HUB }),
      title: hasBreadcrumbs
        ? contentHubData.value.breadcrumb[0].title
        : contentHubData.value.titleBreadcrumb,
    },
    ...remainingBreadcrumbs.map((b) => ({
      title: b.title,
      link: resolveLocalizedRoute({
        name: CMS.ARTICLE_HUB,
        params: { pageCategory: b.slug },
      }),
    })),
    ...(hasBreadcrumbs
      ? [
          {
            title: contentHubData.value.titleBreadcrumb,
            link: resolveLocalizedRoute({
              name: CMS.ARTICLE_HUB,
              params: { pageCategory: routeParams.pageCategory },
            }),
          },
        ]
      : []),
  ]
})

function generateCTALink(cta?: CTALink, ctaText?: string) {
  if (!cta?.link || !ctaText) return undefined

  return {
    link: transformHref({ href: cta.link.href, currentUrl }),
    label: ctaText,
  }
}

function trackBreadcrumbClick(breadcrumbValue: string) {
  trackClick({
    name: 'breadcrumb',
    zone: 'hubBuyingGuide',
    value: { breadcrumbValue },
  })
}
</script>
