<template>
  <div>
    <div
      :class="{ [style?.clampText]: isIntroHidden, [style?.rteParser]: true }"
    >
      <!-- eslint-disable vue/no-v-html -->
      <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
      <div v-if="intro" ref="richTextContainer" v-html="intro" />
      <RichText
        v-else-if="richTextIntro"
        :default-style="false"
        :rich-text="richTextIntro"
      />
    </div>
    <RevLink
      v-if="isIntroHidden"
      data-test="hub-intro-button"
      @click="isIntroHidden = false"
    >
      {{ i18n(translations.readMoreIntro) }}
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, useCssModule } from 'vue'

import type { RichText as RichTextType } from '@backmarket/http-api/src/api-specs-content/models/rich-text'
import RichText from '@backmarket/nuxt-layer-cms/RichText.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { RevLink } from '@ds/components/Link'

import translations from './HubIntro.translations'

const props = defineProps<{ intro?: string; richTextIntro?: RichTextType }>()

const style = useCssModule()

const i18n = useI18n()

const richTextContainer = ref<HTMLElement>()

const introTextContent = ref('')

const isIntroHidden = ref(false)

const TEXT_MAX_CHARACTERS = 500
onMounted(() => {
  if (props.richTextIntro) {
    isIntroHidden.value =
      documentToPlainTextString(props.richTextIntro).length >
      TEXT_MAX_CHARACTERS

    return
  }

  introTextContent.value = richTextContainer?.value?.textContent?.trim?.() || ''
  isIntroHidden.value = introTextContent.value?.length > TEXT_MAX_CHARACTERS
})

defineExpose({
  introTextContent,
})
</script>

<style module>
.clampText div {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clampText div p {
  display: contents;
}

.clampText div p:after {
  content: '\A';
  white-space: pre;
  height: 8px;
  display: flex;
}

.rteParser {
  color: #555555;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
}

.rteParser .overflow {
  overflow: auto;
  width: 100%;
}

.rteParser p,
.rteParser blockquote,
.rteParser ul,
.rteParser ol,
.rteParser table {
  margin: 0 0 8px 0;
}

.rteParser p {
  margin-bottom: 8px;
  line-height: 26px;
}

.rteParser blockquote {
  position: relative;
  padding-left: 16px;
  border-left: 1.5px solid #a486cc;
  margin: 32px 0;
}

/* any type of element can be inserted inside a blockquote */
.rteParser blockquote > * {
  color: #a486cc;
  font-size: 18px;
  font-weight: 300;
  line-height: 128%;
}

.rteParser ul {
  list-style-type: disc;
  padding: 0 0 0 16px;
}

.rteParser li {
  margin-bottom: 24px;
}

.rteParser a {
  color: #111111;
  font-weight: 500;
  text-decoration: underline;
}

.rteParser a:hover,
.rteParser a:focus {
  color: #cab3fa;
}

.rteParser table {
  width: 100%;
}

.rteParser table,
.rteParser tr,
.rteParser td,
.rteParser th {
  border-collapse: collapse;
  border: none;
}

.rteParser tr {
  border-top: 0.1rem solid #e5e5e5;
  color: #555555;
  width: 100%;
}

.rteParser tr:first-child {
  font-weight: 500;
  color: #111111;
}

.rteParser th {
  padding: 16px 16px 16px 0;
}

.rteParser td {
  padding: 16px 16px 16px 0;
  text-align: left;
}

@media (min-width: 992px) {
  .rteParser {
    font-size: 18px;
  }

  .rteParser p {
    line-height: 28px;
  }

  .rteParser blockquote > * {
    font-size: 24px;
    line-height: 129%;
  }

  .rteParser th {
    padding-right: 32px;
  }

  .rteParser td {
    min-width: 180px;
    padding: 16px 32px 16px 0;
  }
}
</style>
